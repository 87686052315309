import React, { useEffect, useState } from "react";
import { Container, Form, Nav, Button, Navbar, Dropdown, Image } from "react-bootstrap";
import axios from "../../../../axios_purchase";
import { Link, useHistory } from "react-router-dom";
import SignInModal from "../../../mall/sub-pages/SignInModal";
import RegisterModal from "../../../mall/sub-pages/RegisterModal";
import ForgetModal from "../../../mall/sub-pages/ForgetModal";
import { useSelector, useDispatch } from "react-redux";
import { FaChevronRight } from "react-icons/fa";
import { updateUserLoggedInData } from "../../../../store/actions/mallActions";
import { toast } from "react-toastify";
import RatingModal from "../../../mall/sub-pages/RatingModal";
import config from "../../../../config";
import SidebarMenuItems from "../../partials/SidebarMenuItems";
import Backimg from "../../../../assets/i/ThemeImages/backvector.svg";
import parse from "html-react-parser";
import CartSidebar from "../../DoughbrosTheme/DoughbrosThemeComponents/CartSidebar";
// import HeaderEditSection from "./HomeModal/HeaderEditSection";

const SmashBurgerHeader = () => {
	const [active, setActive] = useState(false);
	const [blockClass, setBlockClass] = useState("d-none");
	const [productSearch, setProductSearch] = useState([]);
	const history = useHistory();
	const [showSingIn, setShowSingIn] = useState(false);
	const [showModalData, setShowModalData] = useState(false);
	const [showForget, setShowForget] = useState(false);
	const currentLayout = useSelector((state) => state.theme.current_layout);
	const userLoggedInData = useSelector((state) => state.mall.userLoggedInData);
	const currentMenu = useSelector((state) => state.theme.current_menu);
	const wishlistCount = useSelector((state) => state.wishlist.products);
	const [showBackButtonOnHomePage, setshowBackButtonOnHomePage] = useState(true);
	const [scroll, setScroll] = useState(false);
	const cartData = useSelector((state) => state.mall.cartData);
	const [recentOrder, setRecentOrder] = useState("");
	const [activeBtnClass, setActiveBtnClass] = useState("");
	const [welcomeMessage, setWelcomeMessage] = useState(false);
	const [activeUrl, setActiveUrl] = useState("");
	const [show, setShow] = useState(false);
	const [activeCartSideBar, setActiveCartSideBar] = useState(false);
	const [showCartSideBar, setShowCartSideBar] = useState(false);
	const storeData = useSelector((state) => {
		return { ...state.mall.storeFrontData };
	});
	function handleClose() {
		setShow(false);
	}
	// foodTheme
	const dispatch = useDispatch();

	const showModal = (type) => {
		if (type === "signup") {
			setShowModalData(true);
			setShowSingIn(false);
			setShowForget(false);
		} else if (type === "forget") {
			setShowModalData(false);
			setShowSingIn(false);
			setShowForget(true);
		} else {
			setShowModalData(false);
			setShowSingIn(true);
			setShowForget(false);
		}
	};

	const hideModal = (type) => {
		if (type === "signup") {
			setShowModalData(false);
		} else if (type === "forget") {
			setShowForget(false);
		} else {
			setShowSingIn(false);
		}
	};

	function handleInactive() {
		setActive(false);
	}
	function handleInactiveCartSidebar() {
		setActiveCartSideBar(false);
	}

	const changeSearch = (e) => {
		var searchValue = e.target.value;
		if (searchValue.length >= 3 && searchValue != "") {
			let postData = {
				searchValue: searchValue,
				store_id: window.$storeId,
			};
			axios
				.post(window.$storeUrl + window.$apiRoot + "/storefront/search", postData)
				.then((res) => {
					if (res.data.code === "11") {
						setBlockClass("d-block");

						setProductSearch(res.data.data);
					} else {
						setBlockClass("d-none");
						setProductSearch([]);
					}
					// if (res.data.product_searched && res.data.product_searched.length > 0) {
					//   setBlockClass('d-block');
					//   setProductSearch(res.data.product_searched);
					// } else {
					//   setBlockClass('d-none');
					//   setProductSearch([]);
					// }
				})
				.catch();
		} else {
			setBlockClass("d-none");
			setProductSearch([]);
		}
	};
	const selectProduct = (item) => {
		setBlockClass("d-none");
		history.push(
			"/products/" +
				item.product_name
					.split("%")
					.join("-percent")
					.split("/")
					.join("-")
					.split("_")
					.join("-")
					.split(" ")
					.join("-") +
				"~" +
				btoa(item.product_id)
		);
	};
	const searchDetail = (e) => {
		if (!window.$isStoreFront) {
			setBlockClass("d-none");
			setProductSearch([]);
			history.push(`/category-detail/${btoa(e.target.value)}/product`);
		} else {
			e.preventDefault();
		}
	};

	const logoutData = () => {
		localStorage.removeItem("user_data");
		dispatch(
			updateUserLoggedInData({
				mobile_no: "",
				password: "",
				is_loggedIn: false,
				token: "",
				user_id: "",
				user_name: "",
				email: "",
			})
		);
		toast.success("Logged out successfully!", {
			position: toast.POSITION.BOTTOM_RIGHT,
			hideProgressBar: true,
			theme: "dark",
		});
	};

	const goBackNow = () => {
		if (window.location.pathname === "/" || config.base.includes(window.location.pathname)) {
			window.location.href = window.location.href + `?inapp://exit`;
		} else {
			history.go(-1);
		}
	};

	const getMyOrders = () => {
		var userData = {
			token: this.props.userLoggedInData?.token,
			type: "home",
			store_id: window.$storeId,
			isStoreFront: window.$isStoreFront ? "Y" : "N",
		};
		axios
			.post(window.$storeUrl + window.$apiRoot + "/customer/getCustomerOrders", userData)
			.then((res) => {
				if (res.data.status) {
					const recentOrderRetrivedFromCall = res.data.data?.length > 0 ? res.data.data[0] : {};
					this.setState({ recentOrder: recentOrderRetrivedFromCall });
				}
			})
			.catch();
	};

	useEffect(() => {
		if (window.location.pathname.indexOf("shop") > 0) {
			setActiveUrl("shop");
		} else if (window.location.pathname.indexOf("testimonials") > 0) {
			setActiveUrl("testimonials");
		} else if (window.location.pathname.indexOf("faq") > 0) {
			setActiveUrl("faq");
		} else if (window.location.pathname.indexOf("contactus") > 0) {
			setActiveUrl("contactus");
		} else if (window.location.pathname.indexOf("blog") > 0) {
			if (window.$storeId !== "786137") {
			}
			setActiveUrl("blog");
		} else if (window.location.pathname.indexOf("smashfeeds") > 0) {
			setActiveUrl("smashfeeds");
		} else if (window.location.pathname.indexOf("cart-detail") > 0) {
			setActiveUrl("cart-detail");
		} else if (window.location.pathname.indexOf("wishlist") > 0) {
			setActiveUrl("wishlist");
		} else if (window.location.pathname.indexOf("checkout") > 0) {
			setActiveUrl("checkout");
		} else if (window.location.pathname.indexOf("product-detail") > 0) {
			setActiveUrl("product-detail");
		} else if (window.location.pathname.indexOf("products") > 0) {
			setActiveUrl("product-detail");
		} else if (window.location.pathname.indexOf("my-account") > 0) {
			setActiveUrl("my-account");
		} else if (window.location.pathname.indexOf("order-details") > 0) {
			setActiveUrl("order-details");
		} else if (window.location.pathname.indexOf("aboutus") > 0) {
			setActiveUrl("aboutus");
		} else if (window.location.pathname.indexOf("requestquoat") > 0) {
			setActiveUrl("requestquoat");
		} else if (window.location.pathname.indexOf("my-wallet") > 0) {
			setActiveUrl("my-wallet");
		} else if (window.location.pathname.indexOf("view-location") > 0) {
			setActiveUrl("view-location");
		} else if (window.location.pathname.indexOf("page/27/Our-Story") > 0) {
			setActiveUrl("page/27/Our-Story");
		} else if (window.location.pathname.indexOf("page/29/Our-Story") > 0) {
			setActiveUrl("page/29/Our-Story");
		}
		if (window.location.pathname === "/" || config.base.includes(window.location.pathname)) {
			setshowBackButtonOnHomePage(false);
		}

		if (
			storeData?.config[0]?.welcome_msg !== "" &&
			storeData?.config[0]?.show_welcome_msg === "Y" &&
			window.location.pathname === "/"
		) {
			setWelcomeMessage(true);
		}
		if (currentLayout?.theme === "foodTheme") {
			setShowCartSideBar(true);
		} else {
			setShowCartSideBar(false);
		}
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
	}, []);

	const checkOpenLink = (item) => {
		history.push(item?.link);
	};

	const isHomePage = history.location.pathname === "/";
	return (
		<>
			<div
				className={`header_theme_box ${
					welcomeMessage == false || storeData?.config[0]?.welcome_msg === "" ? "header_height" : ""
				} ${isHomePage ? "fixedHeaderclass" : ""}`}
			>
				<div className="header-theme">
					{storeData?.config[0]?.welcome_msg != "" ? (
						<div
							className={
								!welcomeMessage
									? "d-none"
									: "welcome_msgBox position-relative d-flex justify-content-center align-items-center py-2"
							}
						>
							{storeData?.config[0]?.welcome_msg != "" &&
							storeData?.config[0]?.welcome_msg != undefined &&
							storeData?.config[0]?.welcome_msg != null ? (
								<p>{parse(storeData?.config[0]?.welcome_msg)}</p>
							) : (
								""
							)}
							<i className="icon-close-i position-absolute" onClick={() => setWelcomeMessage(false)}></i>
						</div>
					) : (
						""
					)}

					{/* <Container fluid className="email_header d-none d-md-block">
            
          </Container> */}
					<Container fluid className={`navbar_container  ${scroll && "fixed-top"}`}>
						<Navbar expand="lg">
							{window.$isWebView ? (
								<Button
									variant="link"
									className={`bckBtn p-0 mr-2  ${
										!showBackButtonOnHomePage ? "d-none" : "d-md-none d-inline-block"
									}`}
									id="btnBackTo"
									onClick={() => {
										goBackNow();
									}}
								>
									<Image src={Backimg} alt="Back" />
								</Button>
							) : (
								""
							)}

							{/* {
                storeData?.store?.data?.contact_mobile != ''
                  && storeData?.store?.data?.contact_mobile != undefined
                  && storeData?.store?.data?.contact_mobile != null ? (
                    <p className="d-md-block d-none">
                      Call to order:{" "}
                      <span className="font-weight-bold">{storeData?.store?.data?.contact_mobile}</span>
                    </p>
                  ) : ''
              } */}
							<div className=" d-md-flex d-none align-items-center justify-content-center py-2">
								{storeData?.config[0]?.use_logo_text === "Y" &&
								storeData?.config[0]?.logo_text !== "" ? (
									<span
										className="text_logo"
										style={{
											fontSize: `${
												parseInt(storeData?.config[0]?.logo_text_font) > 0
													? storeData?.config[0]?.logo_text_font
													: 16
											}px`,
										}}
									>
										{storeData?.config[0]?.logo_text}
									</span>
								) : storeData?.store?.data?.store_logo != "" &&
								  storeData?.store?.data?.store_logo != undefined &&
								  storeData?.store?.data?.store_logo != null ? (
									<Link to="/" className="position-relative">
										<img src={storeData?.store?.data?.store_logo} alt="" className="storeLogo" />
									</Link>
								) : (
									""
								)}
							</div>
							{storeData?.config[0]?.use_logo_text === "Y" && storeData?.config[0]?.logo_text !== "" ? (
								<span
									className="text_logo"
									style={{
										fontSize:
											parseInt(storeData?.config[0]?.logo_text_font) > 0
												? storeData?.config[0]?.logo_text_font + "px"
												: "16px",
									}}
								>
									{storeData?.config[0]?.logo_text}
								</span>
							) : storeData?.store?.data?.store_logo != "" &&
							  storeData?.store?.data?.store_logo != undefined &&
							  storeData?.store?.data?.store_logo != null ? (
								<div className="d-flex align-items-center mobilelogo-wrapper-outer">
									<Link to="/" className="position-relative mobilelogo-wrapper d-md-none d-block">
										<img src={storeData?.store?.data?.store_logo} alt="" className=" mobileLogo" />
									</Link>
									{storeData?.branches?.data.count > 1 &&
									window.localStorage?.getItem("branchData") &&
									window.localStorage?.getItem("branchData") != "" &&
									window.localStorage?.getItem("branchData") !== "undefined" ? (
										<Button
											variant="link"
											className="p-0 ml-3 text-dark d-none align-items-center d-flex branchTxt"
											onClick={() => {
												window.$showBranchModal = true;
												window.$allowBranchModal = true;
												document.documentElement.scrollTop = 2;
												document.body.scrollTop = 2;
											}}
										>
											<p>
												<b className="font-weight-bold">Branch:</b>{" "}
												{JSON.parse(window.localStorage?.getItem("branchData"))?.name} -{" "}
												{JSON.parse(window.localStorage?.getItem("branchData"))?.branch_name}
											</p>
											<i className="font-12 ml-1">
												<FaChevronRight />
											</i>
										</Button>
									) : (
										""
									)}
								</div>
							) : (
								""
							)}

							<div className="d-block d-lg-none mobbtns">
								<Button
									variant="link"
									className="py-0 px-1 search_btn "
									onClick={() => setActive(true)}
								>
									<i className="icon-search-i"></i>
								</Button>
								{!showCartSideBar ? (
									<>
										<Link
											to="/cart-detail"
											variant="link"
											className="py-0 cart_btn position-relative px-1 "
										>
											<i className="icon-cart-i"></i>

											{parseInt(cartData?.length) > 0 ? (
												<>
													<span>{cartData?.length}</span>
												</>
											) : (
												""
											)}
										</Link>
									</>
								) : (
									<>
										<span
											onClick={() => setActiveCartSideBar(!activeCartSideBar)}
											variant="link"
											style={{ cursor: "pointer" }}
											className="py-0 cart_btn position-relative px-1 "
										>
											<i className="icon-cart-i"></i>

											{parseInt(cartData?.length) > 0 ? (
												<>
													<span>{cartData?.length}</span>
												</>
											) : (
												""
											)}
										</span>
									</>
								)}
							</div>
							<Navbar.Toggle
								aria-controls="basic-navbar-nav"
								onClick={() => setActiveBtnClass("active")}
							/>
							<Navbar.Collapse id="basic-navbar-nav">
								<Nav className="mr-auto ml-auto">
									{currentMenu?.length > 0 &&
										currentMenu?.map((menu, index) => {
											return (
												<a
													className={
														activeUrl === menu?.link?.replace("/", "") ? "active" : ""
													}
													onClick={() => {
														checkOpenLink(menu);
													}}
												>
													{menu.title}
												</a>
											);
										})}
								</Nav>

								<Button variant="link" className="py-0 px-1 search_btn" onClick={() => setActive(true)}>
									<i className="icon-search-i"></i>
								</Button>
								{userLoggedInData?.is_loggedIn && wishlistCount?.length > 0 && (
									<Link
										variant="link"
										to="/wishlist"
										className="py-0 px-1 wishlist_btn position-relative"
									>
										<i className="icon-emptyheart-i"></i>
										<span>{wishlistCount?.length}</span>
									</Link>
								)}

								<Dropdown>
									<Dropdown.Toggle
										id="dropdown-basic"
										className="py-0 px-1 user_btn m-0 bg-transparent ml-1 border-0"
									>
										<i className="icon-userholder"></i>
									</Dropdown.Toggle>
									<Dropdown.Menu>
										{userLoggedInData?.is_loggedIn && (
											<>
												<Dropdown.Item
													onClick={() => {
														history.push("/my-account");
													}}
												>
													My Account
												</Dropdown.Item>
												<Dropdown.Item
													onClick={() => {
														history.push("/order-details");
													}}
												>
													My Orders
												</Dropdown.Item>
												<Dropdown.Item
													onClick={() => {
														history.push("/wishlist");
													}}
												>
													My Wishlist
												</Dropdown.Item>
												{!window.$isFromEasypaisaApp && window.$storeId !== '923821' ? (
													<Dropdown.Item
														onClick={() => {
															history.push("/my-wallet");
														}}
													>
														My Wallet
													</Dropdown.Item>
												) : (
													""
												)}
												{window.$storeId == 726085 ? (
													""
												) : (
													<Dropdown.Item
														onClick={() => {
															history.push("/contactus");
														}}
													>
														Contact Us
													</Dropdown.Item>
												)}
												<Dropdown.Item
													onClick={(e) => {
														logoutData();
													}}
												>
													Logout
												</Dropdown.Item>
											</>
										)}
										{!userLoggedInData?.is_loggedIn && (
											<>
												<Dropdown.Item
													onClick={() => {
														showModal();
													}}
												>
													Login
												</Dropdown.Item>
												<Dropdown.Item
													onClick={() => {
														showModal("signup");
													}}
												>
													Sign Up
												</Dropdown.Item>

												{window.$storeId == 726085 ? (
													""
												) : (
													<Dropdown.Item
														onClick={() => {
															history.push("/contactus");
														}}
													>
														Contact Us
													</Dropdown.Item>
												)}
											</>
										)}
									</Dropdown.Menu>
								</Dropdown>
								{!showCartSideBar ? (
									<>
										<Link
											to="/cart-detail"
											variant="link"
											className="py-0 cart_btn position-relative px-1 "
										>
											<i className="icon-cart-i"></i>

											{parseInt(cartData?.length) > 0 ? (
												<>
													<span>{cartData?.length}</span>
												</>
											) : (
												""
											)}
										</Link>
									</>
								) : (
									<>
										<span
											onClick={() => setActiveCartSideBar(!activeCartSideBar)}
											variant="link"
											style={{ cursor: "pointer" }}
											className="py-0 cart_btn position-relative px-1 "
										>
											<i className="icon-cart-i"></i>

											{parseInt(cartData?.length) > 0 ? (
												<>
													<span>{cartData?.length}</span>
												</>
											) : (
												""
											)}
										</span>
									</>
								)}
							</Navbar.Collapse>
						</Navbar>
					</Container>
					<div
						className={`search_bar ${
							active && "active"
						} position-absolute d-flex align-items-center w-100 ${
							!welcomeMessage ? "searchbar_position" : ""
						}`}
					>
						<Container className="position-relative w-100" fluid>
							<Form.Group controlId="formBasicEmail" className="mb-0">
								<Form.Control
									type="text"
									placeholder="Search Products"
									onChange={(e) => {
										changeSearch(e);
									}}
									onKeyPress={(e) => {
										if (e.key === "Enter" || e.keyCode === 13) {
											searchDetail(e);
										}
									}}
								/>
							</Form.Group>
							<i className="icon-close-i position-absolute" onClick={handleInactive}></i>

							<div
								className={`${blockClass} search-dropdown  position-absolute py-2 w-100 bg-white px-3`}
							>
								{productSearch.length > 0 &&
									productSearch.map((item) => (
										<Link
											onClick={() => {
												setActive(false);
												setBlockClass("d-none");
											}}
											className="font-14  d-flex py-2  border-bottom justify-content-between"
											to={"/product-detail/" + btoa(item.product_id)}
										>
											{item.product_name}
										</Link>
									))}
							</div>
						</Container>
					</div>
				</div>
				<SignInModal showSingIn={showSingIn} hideModal={hideModal} showModal={showModal} />

				<RegisterModal showModalData={showModalData} hideModal={hideModal} showModal={showModal} />

				<ForgetModal showForget={showForget} hideModal={hideModal} showModal={showModal} />
				{window.$isStoreFront &&
				userLoggedInData?.is_loggedIn &&
				recentOrder?.ratings?.rating_id === 0 &&
				recentOrder?.order_status === "65" ? (
					<RatingModal recentOrder={recentOrder} />
				) : (
					""
				)}

				<SidebarMenuItems
					checkOpenLink={checkOpenLink}
					onClickbtn={setActiveBtnClass}
					activebtn={activeBtnClass === "active" ? "active" : ""}
				/>
				<div onClick={setActiveBtnClass} className={`overlaysidebar  overlayspring ${activeBtnClass}`}></div>
			</div>
			{currentLayout?.theme === "foodTheme" && activeCartSideBar ? (
				<CartSidebar
					active={activeCartSideBar}
					setActive={setActiveCartSideBar}
					handleInactiveCartSidebar={handleInactiveCartSidebar}
				/>
			) : (
				""
			)}
		</>
	);
};

export default SmashBurgerHeader;
