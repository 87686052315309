import React, { Fragment, lazy, memo, Suspense, useState, useEffect } from "react";
import { Button, Container, Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import { updateUserLoggedInData } from "../../../../store/actions/mallActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import MetaDataComponent from "../../Subcomponents/Home/MetaDataComponent";
import ReactPixel from "react-facebook-pixel";
import parse from "html-react-parser";
import { ConditionalRender } from "../../../../util/Helper";
const SignInModal = lazy(() => import("../../../mall/sub-pages/SignInModal"));
const ForgetModal = lazy(() => import("../../../mall/sub-pages/ForgetModal"));

const KnowledgeHeader = (props) => {
	const history = useHistory();
	const [showSingIn, setShowSingIn] = useState(false);
	// const [showModalData, setShowModalData] = useState(false);
	const [showForget, setShowForget] = useState(false);
	function AddLibrary(urlOfTheLibrary) {
		const script = document.createElement("script");
		script.src = urlOfTheLibrary;
		script.id = "hs-script-loader";
		script.async = true;
		script.defer = true;
		document.body.appendChild(script);
	}

	useEffect(() => {
		AddLibrary("//js.hs-scripts.com/22766080.js");
	}, []);
	const dispatch = useDispatch();
	const location = useLocation();
	const { storeData, currentMenu, storeFrontData } = useSelector((state) => {
		return {
			storeData: state.mall.storeFrontData,
			currentMenu: state.theme.current_menu,
			storeFrontData: state.mall.storeFrontData?.store?.data,
		};
	});
	// const [welcomeMessage, setWelcomeMessage] = useState(false)

	// useEffect(() => {
	//   if (window.location.pathname === '/' && storeData?.config[0]?.welcome_msg !== '') {
	//     setWelcomeMessage(true)
	//   }
	// }, [])
	const showModal = (type) => {
		if (type === "signup") {
			// setRandomKey(Math.random());
			// setShowModalData(true);
			setShowSingIn(false);
			setShowForget(false);
		} else if (type === "forget") {
			// setRandomKey(Math.random());
			// setShowModalData(false);
			setShowSingIn(false);
			setShowForget(true);
		} else {
			// setRandomKey(Math.random());
			// setShowModalData(false);
			setShowSingIn(true);
			setShowForget(false);
		}
	};

	const hideModal = (type) => {
		if (type === "signup") {
			// setShowModalData(false);
		} else if (type === "forget") {
			setShowForget(false);
		} else {
			setShowSingIn(false);
		}
	};
	// const goBackNow = () => {
	//   if (
	//     window.location.pathname === "/" ||
	//     config.base.includes(window.location.pathname)
	//   ) {
	//     window.location.href = window.location.href + `?inapp://exit`;
	//   } else {
	//     history.go(-1);
	//   }
	// };
	const logoutData = () => {
		localStorage.removeItem("user_data");
		dispatch(
			updateUserLoggedInData({
				mobile_no: "",
				password: "",
				is_loggedIn: false,
				token: "",
				user_id: "",
				user_name: "",
				email: "",
			})
		);
		toast.success("Logged out successfully!", {
			position: toast.POSITION.BOTTOM_RIGHT,
			hideProgressBar: true,
			theme: "dark",
		});
		setTimeout(() => {
			window.location.href = "/";
		}, 500);
	};

	const trackFaceBookPixel = (eventName) => {
		if (storeFrontData.facebook_pixel) {
			ReactPixel.init(storeFrontData.facebook_pixel);
			ReactPixel.trackCustom(eventName, {
				store_name: storeFrontData.name ?? "",
				event: "Button Click",
				value: "",
				currency: "PKR",
			});
		}
	};
	const [hidemsg, setHidemsg] = useState(false);
	return (
		<>
			<MetaDataComponent />
			<div
				className={`${
					hidemsg ||
					storeData?.config[0]?.welcome_msg === "" ||
					storeData?.config[0]?.show_welcome_msg === "N"
						? "hidemsg"
						: ""
				} KnowledgeHeader`}
			>
				<div className="header-theme fixed-top">
					{storeData?.config[0]?.welcome_msg !== "" && storeData?.config[0]?.show_welcome_msg === "Y" ? (
						<div
							className={`${
								hidemsg ? "d-none" : "d-flex"
							} welcome_msgBox position-relative  justify-content-center align-items-center py-2`}
						>
							{storeData?.config[0]?.welcome_msg !== "" &&
							storeData?.config[0]?.welcome_msg !== undefined &&
							storeData?.config[0]?.welcome_msg != null ? (
								<p className="px-4" style={{ textAlign: "center" }}>
									{parse(storeData?.config[0]?.welcome_msg)}
								</p>
							) : (
								""
							)}

							<i onClick={() => setHidemsg(true)} className="icon-close-i position-absolute"></i>
						</div>
					) : (
						""
					)}
					<Container fluid className="navbar_container">
						<Navbar className="p-0" expand="lg">
							<Navbar.Brand onClick={() => history.push("/")} className="position-relative p-0">
								<img src="https://www.dukan.pk/dtemp/ptImg1720163464-37086066628602.png" alt="logo" />
							</Navbar.Brand>
							<div className="d-flex align-items-center ">
								{props.userData?.is_loggedIn &&
								props.userData?.is_applied === "Y" &&
								props.userData?.is_inquired === "Y" ? (
									<>
										<NavLink
											to="/profile?p=appstatus"
											className="text-white d-block d-md-none"
											onClick={() => {
												trackFaceBookPixel("View Status 1");
											}}
										>
											<Button variant="link" className="viewBtn fillBTn text-uppercase mr-3">
												View Status
											</Button>
										</NavLink>
									</>
								) : window.location.href.indexOf("applynow") <= 0 ? (
									<NavLink
										to="/applynow"
										className="text-white d-block d-md-none"
										onClick={() => {
											if (
												props.userData.is_inquired === "Y" &&
												props.userData.is_applied === "N"
											) {
												trackFaceBookPixel("Apply Now 1");
											} else if (
												props.userData.is_inquired === "Y" &&
												props.userData.is_applied === "Y"
											) {
												trackFaceBookPixel("View Status 1");
											} else {
												trackFaceBookPixel("Apply Now 1");
											}
										}}
									>
										{props.userData?.is_loggedIn &&
										(props.userData?.is_applied === "N" || props.userData?.is_inquired === "N") ? (
											<Button variant="link" className="viewBtn fillBTn text-uppercase mr-3">
												{props.userData.is_inquired === "Y" && props.userData.is_applied === "N"
													? "Apply Now"
													: "Apply Now"}
											</Button>
										) : !props.userData.is_loggedIn ? (
											<Button variant="link" className="viewBtn fillBTn text-uppercase mr-3">
												Apply Now
											</Button>
										) : (
											""
										)}
									</NavLink>
								) : (
									""
								)}

								<Navbar.Toggle aria-controls="basic-navbar-nav" />
							</div>
							<Navbar.Collapse id="basic-navbar-nav">
								<Nav className="mr-auto ml-auto">
									<ConditionalRender condition={currentMenu?.length > 0}>
										<NavBarItems items={currentMenu} />
										{/* <a href="https://online.knowledge.tech/" className="nav-link link_highlight"  target="_blank">
										           ONLINE BOOTCAMP
				                                   </a> */}
									</ConditionalRender>
								</Nav>
								<div className="right_menu d-flex align-items-center ">
									{props.userData?.is_loggedIn &&
									props.userData?.is_applied === "Y" &&
									props.userData?.is_inquired === "Y" ? (
										<>
											<NavLink
												to="/profile?p=appstatus"
												className="text-white d-none d-md-block"
												onClick={() => {
													trackFaceBookPixel("View Status 1");
												}}
											>
												<Button variant="link" className="viewBtn fillBTn text-uppercase mr-3">
													View Status
												</Button>
											</NavLink>
										</>
									) : (
										<ApplyNow {...{ userData: props?.userData, trackFaceBookPixel, location }} />
									)}
									{props.userData.is_loggedIn ? (
										<Dropdown>
											<Dropdown.Toggle
												variant="secondary"
												id="dropdown-basic"
												className="bg-transparent border-0 m-0"
											>
												{props.userData?.user_name}
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<Dropdown.Item>
													<Link to="/profile">Profile</Link>
												</Dropdown.Item>

												<Dropdown.Item
													onClick={(e) => {
														logoutData();
													}}
												>
													Logout
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									) : (
										<button
											className="w-auto loginbtn"
											onClick={() => {
												showModal("");
											}}
										>
											LogIn
										</button>
									)}
								</div>
							</Navbar.Collapse>
						</Navbar>
					</Container>
				</div>
			</div>
			<Suspense fallback={""}>
				<SignInModal showSingIn={showSingIn} hideModal={hideModal} showModal={showModal} />
			</Suspense>

			<Suspense fallback={""}>
				<ForgetModal showForget={showForget} hideModal={hideModal} showModal={showModal} />
			</Suspense>
		</>
	);
};

export default memo(KnowledgeHeader);

function NavBarItems({ items }) {
	return items.map(({ title, submenu, link }, index) => (
		<Fragment key={index}>
			<ConditionalRender condition={!link && submenu?.length > 0}>
				<NavDropdown key={index} className="navdropdown" title={title}>
					<NavBarItemsSubmenu items={submenu} />
				</NavDropdown>
			</ConditionalRender>
			<ConditionalRender condition={link && !submenu?.length}>
				{link.includes("http") ? (
					<a key={index} className="nav-link" href={link} target="_blank">
						{title}
					</a>
				) : (
					<NavLink key={index} className="nav-link" to={link}>
						{title}
					</NavLink>
				)}
			</ConditionalRender>
		</Fragment>
	));
}

function NavBarItemsSubmenu({ items }) {
	return items.map(({ title, link }, index) => (
		<NavLink key={index} className="dropdown-item" to={link}>
			{title}
		</NavLink>
	));
}

function ApplyNow({ userData, trackFaceBookPixel, location: { pathname } }) {
	function handleClick() {
		if (userData.is_inquired === "Y" && userData.is_applied === "N") {
			trackFaceBookPixel("Apply Now 1");
		} else if (userData.is_inquired === "Y" && userData.is_applied === "Y") {
			trackFaceBookPixel("View Status 1");
		} else {
			trackFaceBookPixel("Apply Now 1");
		}
	}
	if (pathname.indexOf("applynow") > 0 && userData?.is_loggedIn) return null;
	return (
		<NavLink to="/applynow" className="text-white d-none d-md-block" onClick={handleClick}>
			{userData?.is_loggedIn ? (
				<Button variant="link" className="viewBtn fillBTn text-uppercase mr-3">
					{userData.is_inquired === "Y" && userData.is_applied === "N" ? "Apply Now" : "Apply Now"}
				</Button>
			) : !userData.is_loggedIn ? (
				<Button variant="link" className="viewBtn fillBTn text-uppercase mr-3">
					Apply Now
				</Button>
			) : (
				""
			)}
		</NavLink>
	);
}
