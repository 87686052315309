import React, { useState, useEffect, createRef, useRef } from "react";
import { Nav, Accordion, useAccordionToggle, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AddAddOns, unSetStates, SelectAllAddOns } from "../../../../store/actions/addonsactions";
import { updateCartItems, updateFinalCartData } from "../../../../store/actions/mallActions";
import { makeAnalyticsFunnel } from "../../../../store/actions/CommonActions";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Fragment } from "react";
const AddonsAccordian = (props) => {
	const { products: product, isDeal, reRenderParant, error, idx = "", handleErrorMessage } = props;
	const [panelIndex, setPanelIndex] = useState();

	const CustomToggle = ({ children, eventKey }) => {
		const customOnClick = useAccordionToggle(eventKey, () => {
			setPanelIndex(eventKey === panelIndex ? null : eventKey);
		});
		const customClass = eventKey === panelIndex ? "open-class" : "closed-class";

		return (
			<Nav.Link className={customClass} onClick={customOnClick}>
				{children}
			</Nav.Link>
		);
	};
	const currentAddOns = props.products;
	// const selectedAddOnIds = useSelector((state) => state.addons.selectedAddOnIds);
	// const selectedAddOns = useSelector((state) => state.addons.selectedAddOns);
	// const cartData = useSelector((state) => state.mall.cartData);
	// const storeFrontData = useSelector((state) => state.mall.storeFrontData);
	const {
        selectedAddOnIds,
        multipleSelectedAddOnIds,
        selectedAddOns,
        multipleSelectedAddOns,
        cartData,
        storeFrontData,
    } = useSelector((state) => ({
        selectedAddOnIds: state.addons.selectedAddOnIds,
        multipleSelectedAddOns: state.addons.multipleSelectedAddOns,
        multipleSelectedAddOnIds: isDeal
            ? state.addons.multipleSelectedAddOnIds ?? {}
            : state.addons.multipleSelectedAddOnIds,
        selectedAddOns: state.addons.selectedAddOns,
        cartData: state.mall.cartData,
        storeFrontData: state.mall.storeFrontData,
    }));
	
	const [productPrice, setProductPrice] = useState(0);
	const history = useHistory();
	const [toggleFunctionCall, setToggleFunctionCall] = useState(false);
	const myRefs = useRef([]);
	const mobAddonSectionRef = useRef(null);
	const [quantity, setQuantity] = useState(1);
	const [pprice, setpprice] = useState(props.pPrice);
	myRefs.current =
		currentAddOns?.addons &&
		currentAddOns?.addons?.length > 0 &&
		currentAddOns?.addons?.map((addon, i) => {
			addon?.items?.map((element, key) => myRefs.current[key] ?? createRef());
		});

	const [allAddons, setallAddons] = useState([]);
	const reviewAllAddonsAndAssign = (s = false) => {
		currentAddOns?.addons.map((addon, i) => {
			if (addon.id === "11" || addon.id === "57") {
				addon.showAddon = s;
			} else {
				addon.showAddon = true;
			}
		});
		setallAddons(currentAddOns);
	};

	useEffect(() => {
		if (Object.keys(error).length > 0) {
			mobAddonSectionRef.current.style = "scroll-margin-top: 70px";
			mobAddonSectionRef.current.scrollIntoView({ behavior: "smooth", inline: "start" });
		}
	}, [ error ]);

	// Define an object that maps numbers to their word form
	const numbersToWords = {
		0: "zero",
		1: "one",
		2: "two",
		3: "three",
		4: "four",
		5: "five",
		6: "six",
		7: "seven",
		8: "eight",
		9: "nine",
		10: "ten",
		11: "eleven",
		12: "twelve",
		13: "thirteen",
		14: "fourteen",
		15: "fifteen",
		16: "sixteen",
		17: "seventeen",
		18: "eighteen",
		19: "nineteen",
		20: "twenty",
		30: "thirty",
		40: "forty",
		50: "fifty",
		60: "sixty",
		70: "seventy",
		80: "eighty",
		90: "ninety",
	};

	// Define the convertNumberToWords function
	const convertNumberToWords = (number) => {
		// if number present in object no need to go further
		if (number in numbersToWords) return numbersToWords[number];

		// Initialize the words variable to an empty string
		let words = "";

		// If the number is greater than or equal to 100, handle the hundreds place (ie, get the number of hundres)
		if (number >= 100) {
			// Add the word form of the number of hundreds to the words string
			words += convertNumberToWords(Math.floor(number / 100)) + " hundred";

			// Remove the hundreds place from the number
			number %= 100;
		}

		// If the number is greater than zero, handle the remaining digits
		if (number > 0) {
			// If the words string is not empty, add "and"
			if (words !== "") words += " and ";

			// If the number is less than 20, look up the word form in the numbersToWords object
			if (number < 20) words += numbersToWords[number];
			else {
				// Otherwise, add the word form of the tens place to the words string
				//if number = 37, Math.floor(number /10) will give you 3 and 3 * 10 will give you 30
				words += numbersToWords[Math.floor(number / 10) * 10];

				// If the ones place is not zero, add the word form of the ones place
				if (number % 10 > 0) {
					words += "-" + numbersToWords[number % 10];
				}
			}
		}

		// Return the word form of the number
		return words;
	};
	const showhideAddons = (addonId, subAddonId) => {
		if (addonId === "65" && subAddonId === "203") {
			// Show Addon

			reviewAllAddonsAndAssign(true);
		} else if (addonId === "65") {
			// Hide THose Addons
			reviewAllAddonsAndAssign(false);
		}
	};

	const dispatch = useDispatch();
	const getPrice = (item) => {
		let discount = "";
		if (item?.quantity_available <= 0) {
			return <b>Out of Stock</b>;
		} else if (
			storeFrontData.config["0"].separate_addon_price === "Y" &&
			(parseInt(item?.storeFrontActualPrice) === 0 || item?.storeFrontActualPrice === "")
		) {
			let price = 0;
			if (item.add_ons && item.add_ons?.length > 0) {
				price = parseInt(item?.add_ons[0]?.items[0]?.price);
				item.add_ons.map((i, index) => {
					i.items.map((adon) => {
						if (parseInt(adon.price) < price) {
							price = parseInt(adon.price);
						}
					});
				});
			}
			return (
				<>
					From {getPriceWithCurrency(parseInt(price))} {discount}{" "}
				</>
			);
		} else {
			if (item?.call_for_price === "Y") {
				return <b>Call For Price</b>;
			} else {
				if (
					item?.storeFrontActualPrice !== "" &&
					parseInt(item?.storeFrontActualPrice) !== 0 &&
					parseInt(item?.storeFrontActualPrice) > parseInt(item?.storeFrontPrice)
				) {
					discount = (
						<strike>
							{storeFrontData.config["0"].currency}{" "}
							{parseInt(item?.storeFrontActualPrice).toLocaleString()}
						</strike>
					);
				}

				return (
					<b>
						{storeFrontData.config["0"].currency} {parseInt(item?.storeFrontPrice).toLocaleString()}{" "}
						{discount}{" "}
					</b>
				);
			}
		}
	};

	const getPriceWithCurrency = (price) => {
		if (storeFrontData.config["0"].currency_position === "left") {
			return storeFrontData.config["0"].currency + " " + parseInt(price).toLocaleString();
		} else {
			return (
				<>
					{parseInt(price).toLocaleString() + " "}
					<small>{storeFrontData.config["0"].currency} </small>
				</>
			);
		}
	};

	const compareNumbers = (a, b) => {
		return a - b;
	};
	const addToCart = (newProductArray) => {
		// setError({});
		delete newProductArray?.customer_reviews;
		delete newProductArray?.slider_image;
		delete newProductArray?.description;
		let errors = {};
		let isError = false;
		let data =
			currentAddOns?.addons &&
			currentAddOns?.addons.addons?.map((element) => {
				if (element?.is_required === "Y") {
					let existedIndex = -1;
					let errorMessage = "";
					if (isDeal) {
						existedIndex = multipleSelectedAddOns[product?.product_id]?.findIndex((obj) => {
                            return parseInt(obj.add_on_id) === parseInt(element?.add_on_id);
						});
						errorMessage = product.product_id;
					} else {
						existedIndex = selectedAddOns?.findIndex((obj) => {
							return parseInt(obj.add_on_id) === parseInt(element?.add_on_id);
						});
					}
					if (existedIndex === -1) {
						element["error_message_" + errorMessage] = element?.heading + " add on is required";
						isError = true;
					} else {
						element["error_message" + errorMessage] = "";
					}
				}

				if (element.type === "checkbox") {
					let existedAddons = [];
					let errorMessage = "";
					if (isDeal) {
						existedAddons = multipleSelectedAddOns[product?.product_id]?.filter((obj) => {
                            return obj.add_on_id === element?.add_on_id;
						});
						errorMessage = product.product_id;
					} else {
						existedAddons = selectedAddOns?.filter((obj) => {
							return obj.add_on_id === element?.add_on_id;
						});
					}
					if (
						parseInt(existedAddons?.length) < parseInt(element?.min_option) &&
						element?.is_required === "Y"
					) {
						element["error_message" + errorMessage] =
							"Please select minimum " + element?.min_option + " " + element?.heading + " add on";
						isError = true;
					} else {
						element["error_message" + errorMessage] = "";
					}
					if (parseInt(existedAddons?.length) > parseInt(element?.max_option)) {
						element["error_message" + errorMessage] =
                            "Please select maximum " + element?.max_option + " " + element?.heading + " add on";
						isError = true;
					} else {
						element["error_message" + errorMessage] = "";
					}
					// if (isError) setError(errors);
				}
			});

		if (isError) {
			dispatch(AddAddOns("currentAddOns", data, isDeal ? product.product_id : null));
		}
		
		if (!isError) {
			let cartarray = typeof cartData != "undefined" && cartData.length > 0 ? cartData : [];
			if (newProductArray.varient_id === 0 && newProductArray.varients.length > 0) {
				if (newProductArray.varients.length > 1) {
					newProductArray.varient_id = newProductArray.varients[1].value[0].id;
					newProductArray.slected_varients = `${newProductArray.varients[0].name}:${newProductArray.varients[0].value[0].name},${newProductArray.varients[1].name}:${newProductArray.varients[1].value[0].name}`;
				} else {
					newProductArray.varient_id = newProductArray.varients[0].value[0].id;
					newProductArray.slected_varients = `${newProductArray.varients[0].name}:${newProductArray.varients[0].value[0].name}`;
				}
			}
			var addonString = isDeal ? {} : [];
				console.log("🚀 ~ addToCart ~ isDeal:", isDeal)
			if (isDeal) {
				newProductArray.addons = multipleSelectedAddOns[product?.product_id];
				multipleSelectedAddOns[product?.product_id].map((addon) => {
					console.log("🚀 ~ multipleSelectedAddOns[product?.product_id].map ~ addon:", addon)
					// if (addon.add_on_id === "201" || addon.add_on_id === "203") {
					// 	dispatch(AddAddOns(addon, "radio", product.product_id));
					// } else {
					// 	if(!addonString[product?.product_id]?.length) addonString[product?.product_id] = [addon.add_on_id];
					// 	else addonString[product?.product_id].push(addon.add_on_id);
					// }
				});
			} else if (selectedAddOns?.length > 0 && !isDeal) {
				// newProductArray.addons = selectedAddOns;
				// selectedAddOns.map((addon) => {
				// 	if (addon.id === "201" || addon.id === "203") {
				// 		dispatch(AddAddOns(addon, "radio", null));
				// 	} else {
				// 		addonString.push(addon.id);
				// 	}
				// });
			} else {
				newProductArray.addons = [];
			}

			newProductArray.quantity = quantity;

			let oldadddonString = [];
			if (cartarray.length > 0) {
				cartarray.map((item, index) => {
					if (item?.addons?.length > 0) {
						item?.addons?.map((addon) => {
							oldadddonString.push(addon.id);
						});
					}

					if (
						item.product_id === newProductArray.product_id &&
						oldadddonString.sort(compareNumbers).join("") === addonString.sort(compareNumbers).join("")
					) {
						cartarray.splice(index, 1);
					}
				});
			}

			cartarray = [...cartarray, newProductArray];

			dispatch(updateCartItems(cartarray));
			toast.success("item added to cart", {
				position: toast.POSITION.BOTTOM_RIGHT,
				hideProgressBar: true,
				theme: "dark",
			});

			let cName = newProductArray?.bazar_type_name?.split(" & ").join("_");
			if (window.pixelArray[cName]) {
				ReactPixel.init(window.pixelArray[cName]);
				let tdata = {
					store_name: storeFrontData?.store?.data?.name ?? "",
					event: "add_to_cart",
					ecommerce: {
						currencyCode: "PKR",
						add: {
							// 'add' actionFieldObject measures.
							actionField: { button: "add to cart" }, // 'add to cart' or 'buy it now'
							products: [
								{
									//  adding a product to a shopping cart.
									name: newProductArray.product_name, // Name or ID is required.
									id: newProductArray.product_id,
									price: "",
									brand: storeFrontData?.store?.data?.name ?? "",
									category: newProductArray.bazar_type_name,
									sub_category: "", // If sub-category not available then skip this parameter
									quantity: newProductArray.quantity,
								},
							],
						},
					},
				};
				ReactPixel.track("PageView", tdata);
			}

			TagManager.dataLayer({
				dataLayer: {
					store_name: storeFrontData?.store?.data?.name ?? "",
					event: "add_to_cart",
					ecommerce: {
						currencyCode: "PKR",
						add: {
							// 'add' actionFieldObject measures.
							actionField: { button: "add to cart" }, // 'add to cart' or 'buy it now'
							products: [
								{
									//  adding a product to a shopping cart.
									name: newProductArray.product_name, // Name or ID is required.
									id: newProductArray.product_id,
									price: "",
									brand: storeFrontData?.store?.data?.name ?? "",
									category: "",
									sub_category: "", // If sub-category not available then skip this parameter
									quantity: newProductArray.quantity,
								},
							],
						},
					},
				},
			});
			ReactPixel.track("AddToCart", {
				store_name: storeFrontData?.store?.data?.name ?? "",
				event: "add_to_cart",
				ecommerce: {
					currencyCode: "PKR",
					add: {
						// 'add' actionFieldObject measures.
						actionField: { button: "add to cart" }, // 'add to cart' or 'buy it now'
						products: [
							{
								//  adding a product to a shopping cart.
								name: newProductArray.product_name, // Name or ID is required.
								id: newProductArray.product_id,
								price: newProductArray.price,
								brand: storeFrontData?.store?.data?.name ?? "",
								category: !window.$isStoreFront
									? newProductArray.bazar_category
									: newProductArray.category_id,
								sub_category: !window.$isStoreFront
									? newProductArray.sub_bazar_category
									: newProductArray.sub_category_id, // If sub-category not available then skip this parameter
								quantity: newProductArray.quantity,
							},
						],
					},
				},
			});
			dispatch(makeAnalyticsFunnel("addToCart", JSON.stringify(cartarray), newProductArray.product_id));
			setTimeout(() => {
				calculateShippingCharges();
			}, 1000);
		}
	};

	const getSelectedAddonByAddonItem = (addonId) => {
		let currentSelectedAddsOn = 0;
		if (selectedAddOns.length > 0) {
			selectedAddOns.forEach((element) => {
				if (parseInt(element.add_on_id) === parseInt(addonId)) {
					currentSelectedAddsOn = currentSelectedAddsOn + 1;
				}
			});
		}
		return currentSelectedAddsOn;
	};
	const calculateShippingCharges = () => {
		let uniqueArray = [...new Set(cartData.map((item) => item.store_id))];
		let newArray = [];

		uniqueArray.forEach((item) => {
			let delivery_charges1 = 0;
			let totalSum = 0;
			let printingCost = 0;
			let findArray = cartData.filter((items) => items.store_id === item);

			findArray.forEach((data) => {
				if (window.$isStoreFront && data.texteditor) {
					if (data.texteditor[0]?.text || data.texteditor[1]?.text) {
						printingCost = printingCost + data.quantity * data.text_print_price;
					}
					if (data.texteditor[0]?.src || data.texteditor[0]?.src) {
						printingCost = printingCost + data.quantity * data.image_print_price;
					}
				}

				totalSum = totalSum + data.quantity * data.price;
			});
			if (findArray[0].delivery_charges) {
				delivery_charges1 = delivery_charges1 + parseInt(findArray[0].delivery_charges);
			}
			if (totalSum >= findArray[0]?.delivery_charges_data?.minimum_order) {
				delivery_charges1 = 0;
			}
			newArray.push({
				store_id: item,
				data: findArray,
				total_sum: totalSum,
				delivery_charges: delivery_charges1,
				printingCost: printingCost,
			});
		});
		// localStorage.setItem('final_cart',JSON.stringify(newArray))

		dispatch(updateFinalCartData(newArray));
		dispatch(unSetStates());
		history.push("/shop");
	};

	const addAddOnFunction = (res, type, addon) => (event) => {
		if (res.add_on_id === "65" && res.id !== "203") {
			// Remove all extra selected addons
			for (let i = 0; i < 2; i++) {
				if (isDeal) {
					multipleSelectedAddOns[`${product?.product_id}_${idx}`]?.map((item, i) => {
                        if (item.add_on_id === "11" || item.add_on_id === "57") {
                            dispatch(AddAddOns(item, "radio", `${product?.product_id}_${idx}`));
                        }
                        return null;
                    });
				} else {
					selectedAddOns.map((item, i) => {
						if (item.add_on_id === "11" || item.add_on_id === "57") {
							dispatch(AddAddOns(item, "radio", null));
						}
						return null;
					});
				}
			}

			dispatch(AddAddOns(res, type, isDeal ? `${product?.product_id}_${idx}` : null));
		} else {
			dispatch(AddAddOns(res, type, isDeal ? `${product?.product_id}_${idx}` : null));
		}
		handleErrorMessage({
            res,
            type,
            isDeal,
            product_id: product?.product_id,
            product_name: product?.name,
            idx,
            addon,
            event,
        });
		if (res.id === "203" && toggleFunctionCall) {
			reviewAllAddonsAndAssign(false);
		}
		reRenderParant({key: Math.random(), type: "addon"});
		setToggleFunctionCall(!toggleFunctionCall);
		showhideAddons(res.add_on_id, res.id);
	};

	const selectAll = (e, addon) => {
		dispatch(SelectAllAddOns(e.target.checked, addon));
		setToggleFunctionCall(!toggleFunctionCall);
	};

	// useEffect(() => {
	// 	let price = 0;
	// 	if (isDeal) {
	// 		multipleSelectedAddOns[product?.product_id]?.forEach((element) => {
	// 			price = price + parseFloat(element?.price);
	// 		});
	// 	} else {
	// 		selectedAddOns.forEach((element) => {
	// 			price = price + parseFloat(element?.price);
	// 		});
	// 	}
	// 	// setSelectedAddOnsPrice(parseInt(price));
	// }, [toggleFunctionCall]);

	useEffect(() => {
		calculatePrice();
	}, [props?.products]);

	useEffect(() => {
		// alert(productPrice)
		reRenderParant({key: Math.random(), type: "addon"});
	}, [selectedAddOns, productPrice]);

	const calculatePrice = () => {
		if (props?.products) {
			let item = props?.products;
			if (item?.quantity_available <= 0) {
			} else {
				if (item?.call_for_price === "Y") {
				} else {
					if (item?.st_price !== "" && parseInt(item?.st_price) !== 0 && item?.st_price < item?.total_price) {
						setProductPrice(item?.st_price);
					} else {
						setProductPrice(item?.total_price);
					}
				}
			}
		}
	};
	const seQuatintyAdd = (item) => {
		let quantityData = quantity;
		quantityData = item === "sub" ? (quantityData > 1 ? quantityData - 1 : quantityData) : quantityData + 1;
		setQuantity(quantityData);
		// props.products?.quantity = quantityData
		// dispatch( AddAddOns('currentAddOns',data));
	};

	function handleDisableInput(addon, res) {
		if (getSelectedAddonByAddonItem(addon?.add_on_id) >= addon?.max_option) {
			if (isDeal) return !multipleSelectedAddOnIds[`${product?.product_id}_${idx}`]?.map(Number)?.includes(parseInt(res?.id));
			return !selectedAddOnIds.map(Number)?.includes(parseInt(res?.id))
		}
		return false;
    }

	function handleChecdkbox(res) {
		if (isDeal) {
			if (multipleSelectedAddOnIds[ `${product?.product_id}_${idx}` ]) {
                return multipleSelectedAddOnIds[`${product?.product_id}_${idx}`]?.indexOf(res?.id) > -1
                    ? "checked"
                    : "";
			}
			return ""
		}
        return selectedAddOnIds?.indexOf(parseInt(res?.id)) > -1 ? "checked" : "";
    }

	function StoreMessage() {
		let msg = null;
		if (window.$isStoreFront) return null;
		if (storeFrontData.offline_msg !== "") {
			msg = "The store is closed right now. You can take a look at the store to plan for your next order."
		}
		if (window.$isStoreFront && storeFrontData.delivery_time_over === "Y") {
			msg = storeFrontData.business_time_msg
		}
		return <p className="detail-msg">{msg}</p>
	}

	const errorMessage = ({ add_on_id }) => {
        let errorMsg = `error_message_${product?.product_id}`;
        let idxMsg = Number.isSafeInteger(idx) ? `_${idx}` : "";
        return error[`${errorMsg}_addon_id_${add_on_id}${idxMsg}`] ?? "";
    };

    return (
        <>
            <StoreMessage />
            {/* addons-desktop */}
            <Accordion defaultActiveKey={1} className="addons-accordian mt-4 ">
                {currentAddOns?.addons &&
                    currentAddOns?.addons.map((addon, addonindex) => {
                        return (
                            <Fragment key={addonindex}>
                                <CustomToggle eventKey={addonindex + 1}>
                                    <h3>{addon?.heading}</h3>
                                   
                                    <span>
                                        Choose {addon?.type == "checkbox" ? "Multiple" : "One"} (
                                        {addon?.is_required == "N" || addon?.is_required == ""
                                            ? "Optional"
                                            : "Required"}
                                        )
                                    </span>
                                    <svg
                                        className="close-arrow-accordian"
                                        width="34"
                                        height="32"
                                        viewBox="0 0 34 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0 5C0 2.23858 2.23858 0 5 0H29C31.7614 0 34 2.23858 34 5V27C34 29.7614 31.7614 32 29 32H5C2.23858 32 0 29.7614 0 27V5Z"
                                            fill="#1D1B20"
                                        />
                                        <path
                                            d="M21 14L17 18L13 14"
                                            stroke="white"
                                            stroke-width="1.6"
                                            stroke-linecap="round"
                                        />
                                    </svg>
                                    <svg
                                        className="open-arrow-accordian"
                                        width="34"
                                        height="32"
                                        viewBox="0 0 34 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0 5C0 2.23858 2.23858 0 5 0H29C31.7614 0 34 2.23858 34 5V27C34 29.7614 31.7614 32 29 32H5C2.23858 32 0 29.7614 0 27V5Z"
                                            fill="#1D1B20"
                                        />
                                        <path
                                            d="M21 18L17 14L13 18"
                                            stroke="white"
                                            stroke-width="1.6"
                                            stroke-linecap="round"
                                        />
                                    </svg>
                                </CustomToggle>
                                <Accordion.Collapse eventKey={addonindex + 1} key={addonindex}>
                                    <div className="inner-addons-wrapper">
                                        {addon?.items &&
                                            addon?.items.map((res, i) => {
                                                if (isDeal) {
                                                    res.product_id = product.product_id;
                                                    res.product_name = product.name;
                                                    res.deal_quantity = product.deal_quantity;
                                                }
                                                return addon.type === "checkbox" ? (
                                                    <>
                                                        <div
                                                            className="d-flex justify-content-between align-items-center mt-3"
                                                            key={i}
                                                        >
                                                            <label className="check-label mb-0 f-14 pt-0  mt-0 d-flex  align-items-center">
                                                                <p> {res.name}</p>
                                                                <input
                                                                    type="checkbox"
                                                                    ref={myRefs.current[i]}
                                                                    disabled={handleDisableInput(addon, res)}
                                                                    checked={handleChecdkbox(res)}
                                                                    onChange={addAddOnFunction(res, addon?.type, addon)}
                                                                />
                                                                <span className="checkmark addsOn_Checkmark"></span>
                                                            </label>
                                                            <h4 className="f-16">
                                                                {res?.price > 0 ? getPriceWithCurrency(res?.price) : ""}
                                                            </h4>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div
                                                        className="d-flex justify-content-between align-items-center mt-3"
                                                        key={i}
                                                    >
                                                        <label className="check-label mb-0 f-14 pt-0  mt-0 d-flex  align-items-center">
                                                            <p> {res.name}</p>
                                                            <input
                                                                type="radio"
                                                                ref={myRefs.current[i]}
                                                                checked={handleChecdkbox(res)}
                                                                name={`${addon?.id}_${idx}`}
                                                                onClick={addAddOnFunction(res, addon?.type, addon)}
                                                            />
                                                            <span className="checkmark addsOn_Checkmark radio"></span>
                                                        </label>
                                                        <h4 className="f-16">
                                                            {res?.price > 0 ? getPriceWithCurrency(res?.price) : ""}
                                                        </h4>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </Accordion.Collapse>
								<p className="text-danger font-14 mb-1 addonserror">{errorMessage({ add_on_id: addon?.add_on_id })}</p>
                            </Fragment>
                        );
                    })}
            </Accordion>

            {/* addons-mobile */}

            <div ref={mobAddonSectionRef} className="addons-container-m d-block d-md-none">
                {currentAddOns?.addons &&
                    currentAddOns?.addons.map((addon, addonindex) => {
                        return (
                            <div key={addonindex} className="addons-container-one">
                                <div
                                    class=" nav-link d-flex justify-content-between align-items-center p-0 flex-wrap"
                                    role="button"
                                >
                                    <h3 className="font-16 font-weight-bold">{addon?.heading}</h3>
                                   
                                    <span className="font-11">
                                        Choose {addon?.type === "checkbox" ? "Multiple" : "One"} (
                                        {addon?.is_required == "N" || addon?.is_required == ""
                                            ? "Optional"
                                            : "Required"}
                                        )
                                    </span>
                                </div>
                                <div className="addons-options-wrapper d-flex justify-content-between flex-wrap">
                                    {addon?.items &&
                                        addon?.items.map((res, i) => {
                                            if (isDeal) {
                                                res.product_id = product.product_id;
                                                res.product_name = product.name;
                                                res.deal_quantity = product.deal_quantity;
											}
											let active = handleChecdkbox(res) === "checked" ? "active" : "";
                                            return addon.type === "checkbox" ? (
                                                <>
                                                    <Button
                                                        ref={myRefs.current[i]}
                                                        disabled={handleDisableInput(addon, res)}
                                                        onClick={addAddOnFunction(res, addon?.type, addon)}
                                                        className={active}
                                                        checked={!active}
                                                        variant="link"
                                                        name={addon?.id}
                                                    >
                                                        {res.name}{" "}
                                                        {res?.price > 0 ? getPriceWithCurrency(res?.price) : ""}
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button
                                                        ref={myRefs.current[i]}
                                                        disabled={handleDisableInput(addon, res)}
                                                        className={active}
                                                        checked={!active}
                                                        variant="link"
                                                        name={addon?.id}
                                                        onClick={addAddOnFunction(res, addon?.type, addon)}
                                                    >
                                                        {res.name}{" "}
                                                        {res?.price > 0 ? getPriceWithCurrency(res?.price) : ""}
                                                    </Button>
                                                </>
                                            );
                                        })}
                                </div>
								<p className="text-danger font-14 mb-1 ">
                                        {errorMessage({ add_on_id: addon?.add_on_id })}
                                    </p>
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default AddonsAccordian;
