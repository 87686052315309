import React, {createRef, useCallback, useEffect, useRef, useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AddAddOns, unSetStates, SelectAllAddOns } from "../../../../store/actions/addonsactions";
import { updateCartItems, updateFinalCartData } from "../../../../store/actions/mallActions";
import { makeAnalyticsFunnel } from "../../../../store/actions/CommonActions";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import { toast } from "react-toastify";
import axios from "../../../../axios_purchase";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { AddOnsSection } from "../../../mall/sub-pages/ProductDetail/AddOns";
const SmashAddsOnModal = (props) => {
    const { currentAddOns, selectedAddOnIds, selectedAddOns, cartData, storeFrontData } = useSelector(
        ({ addons, mall }) => ({
            currentAddOns: addons.currentAddOns,
            selectedAddOnIds: addons.selectedAddOnIds,
            selectedAddOns: addons.selectedAddOns,
            cartData: mall.cartData,
            storeFrontData: mall.storeFrontData,
        })
    );
    // const selectedAddOnIds = useSelector((state) => state.addons.);
    // const selectedAddOns = useSelector((state) => state.addons.selectedAddOns);
    // const cartData = useSelector((state) => state.mall.cartData);
    // const storeFrontData = useSelector((state) => state.mall.storeFrontData);
    const [selectedAddOnsPrice, setSelectedAddOnsPrice] = useState(0);
    const [productPrice, setProductPrice] = useState(0);
    const history = useHistory();
    const [toggleFunctionCall, setToggleFunctionCall] = useState(false);
    const myRefs = useRef([]);
    const [quantity, setQuantity] = useState(1);
    const [error, setError] = useState({});
    const [updateKey] = useState(Math.random());
    const [addToCart, setAddTocart] = useState();

    myRefs.current =
        currentAddOns?.length > 0 &&
        currentAddOns?.addons?.map((addon, i) => {
            addon?.items?.map((element, key) => myRefs.current[key] ?? createRef());
        });

    const [allAddons, setallAddons] = useState([]);
    const [xkey, setxkey] = useState(Math.random());
    // const currentAddOns = useSelector(state => state.addons.currentAddOns);

    const reviewAllAddonsAndAssign = (s = false) => {
        currentAddOns?.addons?.map((addon, i) => {
            if (addon.id === "11" || addon.id === "57") {
                addon.showAddon = s;
            } else {
                addon.showAddon = true;
            }
        });
        setallAddons(currentAddOns);
    };
    // Define an object that maps numbers to their word form
    const numbersToWords = {
        0: "zero",
        1: "one",
        2: "two",
        3: "three",
        4: "four",
        5: "five",
        6: "six",
        7: "seven",
        8: "eight",
        9: "nine",
        10: "ten",
        11: "eleven",
        12: "twelve",
        13: "thirteen",
        14: "fourteen",
        15: "fifteen",
        16: "sixteen",
        17: "seventeen",
        18: "eighteen",
        19: "nineteen",
        20: "twenty",
        30: "thirty",
        40: "forty",
        50: "fifty",
        60: "sixty",
        70: "seventy",
        80: "eighty",
        90: "ninety",
    };

    // Define the convertNumberToWords function
    const convertNumberToWords = (number) => {
        // if number present in object no need to go further
        if (number in numbersToWords) return numbersToWords[number];

        // Initialize the words variable to an empty string
        let words = "";

        // If the number is greater than or equal to 100, handle the hundreds place (ie, get the number of hundres)
        if (number >= 100) {
            // Add the word form of the number of hundreds to the words string
            words += convertNumberToWords(Math.floor(number / 100)) + " hundred";

            // Remove the hundreds place from the number
            number %= 100;
        }

        // If the number is greater than zero, handle the remaining digits
        if (number > 0) {
            // If the words string is not empty, add "and"
            if (words !== "") words += " and ";

            // If the number is less than 20, look up the word form in the numbersToWords object
            if (number < 20) words += numbersToWords[number];
            else {
                // Otherwise, add the word form of the tens place to the words string
                //if number = 37, Math.floor(number /10) will give you 3 and 3 * 10 will give you 30
                words += numbersToWords[Math.floor(number / 10) * 10];

                // If the ones place is not zero, add the word form of the ones place
                if (number % 10 > 0) {
                    words += "-" + numbersToWords[number % 10];
                }
            }
        }

        // Return the word form of the number
        return words;
    };
    const showhideAddons = (addonId, subAddonId) => {
        if (addonId === "65" && subAddonId === "203") {
            // Show Addon

            reviewAllAddonsAndAssign(true);
        } else if (addonId === "65") {
            // Hide THose Addons
            reviewAllAddonsAndAssign(false);
        }
    };

    const dispatch = useDispatch();
    const getPrice = (item) => {
        let discount = "";
        if (item?.quantity_available <= 0) {
            return <b>Out of Stock</b>;
        } else if (
            storeFrontData.config["0"]?.separate_addon_price === "Y" &&
            (parseInt(item?.storeFrontActualPrice) === 0 || item?.storeFrontActualPrice === "")
        ) {
            let price = 0;
            if (item.add_ons && item.add_ons?.length > 0) {
                price = parseInt(item?.add_ons[0]?.items[0]?.price);
                item.add_ons.map((i, index) => {
                    i.items.map((adon) => {
                        if (parseInt(adon.price) < price) {
                            price = parseInt(adon.price);
                        }
                    });
                });
            }
            return (
                <>
                    From {getPriceWithCurrency(parseInt(price))} {discount}{" "}
                </>
            );
        } else {
            if (item?.call_for_price === "Y") {
                return <b>Call For Price</b>;
            } else {
                if (
                    parseInt(item?.storeFrontActualPrice) !== "" &&
                    parseInt(item?.storeFrontActualPrice) !== 0 &&
                    parseInt(item?.storeFrontActualPrice) > parseInt(item?.storeFrontPrice)
                ) {
                    discount = (
                        <strike>
                            {storeFrontData.config["0"]?.currency}{" "}
                            {parseInt(item?.storeFrontActualPrice).toLocaleString()}
                        </strike>
                    );
                }

                return (
                    <b>
                        {storeFrontData.config["0"]?.currency} {parseInt(item?.storeFrontPrice).toLocaleString()}{" "}
                        {discount}{" "}
                    </b>
                );
            }
        }
    };

    const getPriceWithCurrency = (price) => {
        if (storeFrontData.config["0"]?.currency_position === "left") {
            return storeFrontData.config["0"]?.currency + " " + parseInt(price).toLocaleString();
        } else {
            return (
                <>
                    {parseInt(price).toLocaleString() + " "}
                    <small>{storeFrontData.config["0"]?.currency} </small>
                </>
            );
        }
    };
    useEffect(() => {
        setQuantity(props?.products?.quantity && props?.products?.quantity > 0 ? props?.products?.quantity : 1);
        reviewAllAddonsAndAssign(false);

        if (props.show) {
            setxkey(Math.random());
            setToggleFunctionCall(!toggleFunctionCall);
        }
    }, [props.show]);
    const fetchProductDetail = () => {
        var productId = currentAddOns?.product_id;
        const postData = {
            product_id: productId,
            store_id: window.$storeId,
        };
        let count = 0;
        let sum = 0;
        let items = [];
        axios
            .post(window.$storeUrl + window.$apiRoot + "/mall/productDetail", postData)
            .then((res) => {
                addToCart(res.data.product_data);
            })
            .catch((err) => {});
    };

    const compareNumbers = (a, b) => {
        return a - b;
    };
    // const addToCart = (newProductArray) => {
    //     delete newProductArray?.customer_reviews;
    //     delete newProductArray?.slider_image;
    //     delete newProductArray?.description;
    //     let errors = {};
    //     let isError = false;
    //     let data = currentAddOns?.addons?.map((element) => {
    //         if (element?.is_required == "Y") {
    //             const existedIndex = selectedAddOns?.findIndex((obj) => {
    //                 return obj.add_on_id == element?.add_on_id;
    //             });

    //             if (existedIndex == -1) {
    //                 element["error_message"] = element?.heading + " add on is required";
    //                 isError = true;
    //             } else {
    //                 element["error_message"] = "";
    //             }
    //         }

    //         if (element.type == "checkbox") {
    //             const existedAddons = selectedAddOns?.filter((obj) => {
    //                 return obj.add_on_id == element?.add_on_id;
    //             });
    //             if (parseInt(existedAddons?.length) < parseInt(element?.min_option) && element?.is_required == "Y") {
    //                 element["error_message"] =
    //                     "Please select minimum " + element?.min_option + " " + element?.heading + " add on";
    //                 isError = true;
    //             } else {
    //                 element["error_message"] = "";
    //             }
    //             if (parseInt(existedAddons?.length) > parseInt(element?.max_option)) {
    //                 element["error_message"] =
    //                     "Please select maximum " + element?.max_option + " " + element?.heading + " add on";
    //                 isError = true;
    //             } else {
    //                 element["error_message"] = "";
    //             }
    //             if (isError) {
    //                 setError(errors);
    //             }
    //         }
    //     });

    //     if (isError) {
    //         dispatch(AddAddOns("currentAddOns", data));
    //     }

    //     if (!isError) {
    //         let cartarray = typeof cartData != "undefined" && cartData.length > 0 ? cartData : [];
    //         if (newProductArray.varient_id == 0 && newProductArray.varients.length > 0) {
    //             if (newProductArray.varients.length > 1) {
    //                 newProductArray.varient_id = newProductArray.varients[1].value[0].id;
    //                 newProductArray.slected_varients = `${newProductArray.varients[0].name}:${newProductArray.varients[0].value[0].name},${newProductArray.varients[1].name}:${newProductArray.varients[1].value[0].name}`;
    //             } else {
    //                 newProductArray.varient_id = newProductArray.varients[0].value[0].id;
    //                 newProductArray.slected_varients = `${newProductArray.varients[0].name}:${newProductArray.varients[0].value[0].name}`;
    //             }
    //         }
    //         var addonString = [];
    //         if (selectedAddOns?.length > 0) {
    //             newProductArray.addons = selectedAddOns;
    //             selectedAddOns.map((addon) => {
    //                 if (addon.id === "201" || addon.id === "203") {
    //                     dispatch(AddAddOns(addon, "radio"));
    //                 } else {
    //                     addonString.push(addon.id);
    //                 }
    //             });
    //         } else {
    //             newProductArray.addons = [];
    //         }

    //         newProductArray.quantity = quantity;

    //         let oldadddonString = [];
    //         let newcartArr = [];

    //         if (cartarray.length > 0) {
    //             cartarray.map((item, index) => {
    //                 if (item?.addons?.length > 0) {
    //                     item?.addons?.map((addon) => {
    //                         oldadddonString.push(addon.id);
    //                     });
    //                 }

    //                 if (
    //                     item.product_id === newProductArray.product_id &&
    //                     oldadddonString.sort(compareNumbers).join("") === addonString.sort(compareNumbers).join("")
    //                 ) {
    //                     cartarray.splice(index, 1);
    //                 }
    //             });
    //         }

    //         cartarray = [...cartarray, newProductArray];

    //         dispatch(updateCartItems(cartarray));
    //         toast.success("item added to cart", {
    //             position: toast.POSITION.BOTTOM_RIGHT,
    //             hideProgressBar: true,
    //             theme: "dark",
    //         });

    //         let cName = newProductArray?.bazar_type_name?.split(" & ").join("_");
    //         if (window.pixelArray[cName]) {
    //             ReactPixel.init(window.pixelArray[cName]);
    //             let tdata = {
    //                 store_name: storeFrontData?.store?.data?.name ?? "",
    //                 event: "add_to_cart",
    //                 ecommerce: {
    //                     currencyCode: "PKR",
    //                     add: {
    //                         // 'add' actionFieldObject measures.
    //                         actionField: { button: "add to cart" }, // 'add to cart' or 'buy it now'
    //                         products: [
    //                             {
    //                                 //  adding a product to a shopping cart.
    //                                 name: newProductArray.product_name, // Name or ID is required.
    //                                 id: newProductArray.product_id,
    //                                 price: "",
    //                                 brand: storeFrontData?.store?.data?.name ?? "",
    //                                 category: newProductArray.bazar_type_name,
    //                                 sub_category: "", // If sub-category not available then skip this parameter
    //                                 quantity: newProductArray.quantity,
    //                             },
    //                         ],
    //                     },
    //                 },
    //             };
    //             ReactPixel.track("PageView", tdata);
    //         }

    //         TagManager.dataLayer({
    //             dataLayer: {
    //                 store_name: storeFrontData?.store?.data?.name ?? "",
    //                 event: "add_to_cart",
    //                 ecommerce: {
    //                     currencyCode: "PKR",
    //                     add: {
    //                         // 'add' actionFieldObject measures.
    //                         actionField: { button: "add to cart" }, // 'add to cart' or 'buy it now'
    //                         products: [
    //                             {
    //                                 //  adding a product to a shopping cart.
    //                                 name: newProductArray.product_name, // Name or ID is required.
    //                                 id: newProductArray.product_id,
    //                                 price: "",
    //                                 brand: storeFrontData?.store?.data?.name ?? "",
    //                                 category: "",
    //                                 sub_category: "", // If sub-category not available then skip this parameter
    //                                 quantity: newProductArray.quantity,
    //                             },
    //                         ],
    //                     },
    //                 },
    //             },
    //         });
    //         ReactPixel.track("AddToCart", {
    //             store_name: storeFrontData?.store?.data?.name ?? "",
    //             event: "add_to_cart",
    //             ecommerce: {
    //                 currencyCode: "PKR",
    //                 add: {
    //                     // 'add' actionFieldObject measures.
    //                     actionField: { button: "add to cart" }, // 'add to cart' or 'buy it now'
    //                     products: [
    //                         {
    //                             //  adding a product to a shopping cart.
    //                             name: newProductArray.product_name, // Name or ID is required.
    //                             id: newProductArray.product_id,
    //                             price: newProductArray.price,
    //                             brand: storeFrontData?.store?.data?.name ?? "",
    //                             category: !window.$isStoreFront
    //                                 ? newProductArray.bazar_category
    //                                 : newProductArray.category_id,
    //                             sub_category: !window.$isStoreFront
    //                                 ? newProductArray.sub_bazar_category
    //                                 : newProductArray.sub_category_id, // If sub-category not available then skip this parameter
    //                             quantity: newProductArray.quantity,
    //                         },
    //                     ],
    //                 },
    //             },
    //         });
    //         dispatch(makeAnalyticsFunnel("addToCart", JSON.stringify(cartarray), newProductArray.product_id));
    //         setTimeout(() => {
    //             calculateShippingCharges();
    //         }, 1000);
    //     }
    // };

    const getSelectedAddonByAddonItem = (addonId) => {
        let currentSelectedAddsOn = 0;
        if (selectedAddOns.length > 0) {
            selectedAddOns.forEach((element) => {
                if (parseInt(element.add_on_id) === parseInt(addonId)) {
                    currentSelectedAddsOn = currentSelectedAddsOn + 1;
                }
            });
        }
        return currentSelectedAddsOn;
    };
    const calculateShippingCharges = () => {
        let uniqueArray = [...new Set(cartData.map((item) => item.store_id))];
        let newArray = [];

        uniqueArray.forEach((item) => {
            let delivery_charges1 = 0;
            let totalSum = 0;
            let printingCost = 0;
            let findArray = cartData.filter((items) => items.store_id === item);

            findArray.forEach((data) => {
                if (window.$isStoreFront && data.texteditor) {
                    if (data.texteditor[0]?.text || data.texteditor[1]?.text) {
                        printingCost = printingCost + data.quantity * data.text_print_price;
                    }
                    if (data.texteditor[0]?.src || data.texteditor[0]?.src) {
                        printingCost = printingCost + data.quantity * data.image_print_price;
                    }
                }

                totalSum = totalSum + data.quantity * data.price;
            });
            if (findArray[0].delivery_charges) {
                delivery_charges1 = delivery_charges1 + parseInt(findArray[0].delivery_charges);
            }
            if (totalSum >= findArray[0]?.delivery_charges_data?.minimum_order) {
                delivery_charges1 = 0;
            }
            newArray.push({
                store_id: item,
                data: findArray,
                total_sum: totalSum,
                delivery_charges: delivery_charges1,
                printingCost: printingCost,
            });
        });
        dispatch(updateFinalCartData(newArray));
        dispatch(unSetStates());
        if (window.$storeId === "969159") {
            history.push("/");
        } else {
            history.push("/shop");
        }
    };

    const addAddOnFunction = (res, type) => {
        if (res.add_on_id === "65" && res.id !== "203") {
            // Remove all extra selected addons
            for (let i = 0; i < 2; i++) {
                selectedAddOns.map((item, i) => {
                    if (item.add_on_id === "11" || item.add_on_id === "57") {
                        dispatch(AddAddOns(item, "radio"));
                    }
                });
            }

            dispatch(AddAddOns(res, type));
        } else {
            dispatch(AddAddOns(res, type));
        }

        if (res.id === "203" && toggleFunctionCall) {
            reviewAllAddonsAndAssign(false);
        }

        setToggleFunctionCall(!toggleFunctionCall);
        showhideAddons(res.add_on_id, res.id);
    };

    const selectAll = (e, addon) => {
        dispatch(SelectAllAddOns(e.target.checked, addon));
        setToggleFunctionCall(!toggleFunctionCall);
    };

    // useEffect(() => {
    //     let price = 0;
    //     selectedAddOns.forEach((element) => {
    //         price = price + parseFloat(element?.price);
    //     });

    //     setSelectedAddOnsPrice(parseInt(price));
    // }, [toggleFunctionCall]);

    useEffect(() => {
        calculatePrice();
    }, [currentAddOns]);

    const calculatePrice = () => {
        if (currentAddOns?.product) {
            let item = currentAddOns?.product;
            if (item?.quantity_available <= 0) {
            } else {
                if (item?.call_for_price === "Y") {
                } else {
                    if (
                        item?.storeFrontActualPrice !== "" &&
                        parseInt(item?.storeFrontActualPrice) !== 0 &&
                        item?.storeFrontActualPrice < item?.storeFrontPrice
                    ) {
                        setProductPrice(item?.storeFrontActualPrice);
                    } else {
                        setProductPrice(item?.storeFrontPrice);
                    }
                }
            }
        }
    };
    const seQuatintyAdd = (item) => {
        let quantityData = quantity;
        quantityData = item === "sub" ? (quantityData > 1 ? quantityData - 1 : quantityData) : quantityData + 1;
        setQuantity(quantityData);
        props.products.quantity = quantityData;
        // dispatch( AddAddOns('currentAddOns',data));
    };

    const addToCartFromModal = useCallback(
        (fun) => {
            if (addToCart === undefined && typeof fun === "function") setAddTocart(() => fun);
        },
        [addToCart]
    );

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                key={xkey}
                className="SmashAddsOnModal"
                size="lg"
                centered
            >
                <Modal.Header closeButton className="p-0 border-0 mb-0"></Modal.Header>
                <Modal.Body className="px-md-2 px-0">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="font-26 font-weight-bold">
                            {currentAddOns?.product_name}{" "}
                            {currentAddOns?.variation_name != "" ? "( " + currentAddOns?.variation_name + ")" : ""}
                        </p>
                        <p className="font-26 font-weight-bold">{getPrice(currentAddOns?.product)}</p>
                    </div>
                    <div className="addsOn-container" key={updateKey}>
                        <AddOnsSection
                            {...{
                                products: currentAddOns?.product ?? props?.products,
                                showStikcyActionBar: false,
                                addToCartFromModal,
                                setSelectedAddOnsPrice,
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="position-absolute ">
                    <div className="d-flex align-items-center justify-content-md-between justify-content-center w-100 m-auto pl-md-0 pr-md-4 footerqty">
                        <div className="quantity-box d-md-flex d-flex align-items-center justify-content-between">
                            <Button
                                variant="link"
                                className={`p-0 pluscartbtn font-26 ${quantity > 1 ? "" : "in-active"}`}
                                onClick={(e) => seQuatintyAdd("sub")}
                            >
                                {/* <Image
									src={`${quantity > 1 ? "/assets/i/minusnew.svg" : "/assets/i/minusdisable.svg"}`}
								/> */}
                                {quantity > 1 ? (
                                    <FaMinusCircle />
                                ) : (
                                    <FaMinusCircle className="disabled-cart in-active" />
                                )}
                            </Button>
                            <span>{quantity}</span>
                            <Button
                                variant="link"
                                className="p-0 font-26 pluscartbtn"
                                onClick={(e) => {
                                    seQuatintyAdd("add");
                                }}
                            >
                                {/* <Image src="/assets/i/plusnew.svg" /> */}
                                <FaPlusCircle />
                            </Button>
                        </div>
                        <Button
                            variant="link"
                            className="AddsOn_cart d-flex align-items-center justify-content-between"
                            onClick={addToCart}
                        >
                            {/* <span>0</span> */}
                            <p>Add To Cart</p>
                            <p>
                                {storeFrontData.config["0"]?.currency}{" "}
                                {parseFloat((parseFloat(selectedAddOnsPrice) + parseInt(productPrice)) * quantity)}
                            </p>
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SmashAddsOnModal;
