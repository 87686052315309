import React from "react";
import { Container, Button, Image, Row, Col } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { whatsAppChatPlugin } from "../../../../constants";
import WhatsappContact  from "../../../../components/storefront/UI/WhatsappChat";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsYoutube,
  BsTwitter,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
const KsFooter = () => {
  const storeFrontData = useSelector((state) => {
    return { ...state.mall.storeFrontData };
  });
  return (
    <div className="d-none d-md-block spring_footer py-3">
      <div
        className={`p-0  fixed-bottom whatsapp_btn`}
      >
        {(storeFrontData.store.show_contact_whatsapp === "Y" ||
          storeFrontData.store.show_contact_mobile === "Y") &&
          storeFrontData?.activePluginsIds.includes(
            whatsAppChatPlugin
          ) ? (
            <WhatsappContact
                whatsapp={storeFrontData.whatsapp}
              />
          ) : (
            ""
          )}
      </div>
      <Container>
        <div className="top-footer">
          <Row>
            <Col md="3">
              <div className="footer_box">
                <h4>Useful Links</h4>
                <div className="footer_Links">
                  <Link to="/" className="d-block font-12"> Home</Link>
                  <Link to="/executive-team" className="d-block font-12"> Executive Team</Link>
                  <Link to="/knowledge-partner" className="d-block font-12">  Knowledge Partners</Link>
                  <Link to="/university" className="d-block font-12"> University Alliances</Link>
                  <Link to="/knowledge-testimonials" className="d-block font-12">Testimonials</Link>
                  <Link to="/technical-programmes" className="d-block font-12">Technology</Link>
                  <Link to="/soft-skill" className="d-block font-12"> Soft Skills</Link>
                  <Link to="/contactus" className="d-block font-12">Contact Us</Link>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="footer_box">
                <h4>Contact Us</h4>
                <div className="footer_Links">

                  <a className="d-block font-12" href="mailto:info@knowledge.tech"> Email: info@knowledge.tech</a>

                  <Link className="d-block font-12">Call us: +923277777272</Link>
                  <Link className="d-block font-12">
                    Time: Mon-Fri: 10:00 AM - 5:00 PM
                  </Link>
                </div>
              </div>
            </Col>

            <Col md="2">
              <div className="footer_box">
                <h4>Follow Us</h4>
                <div className="d-flex footer_Links flex-column">
                  <a
                    target="_blank"
                    href="https://twitter.com/knowstreamstech"
                    className="d-flex align-items-center"
                  >
                    <i>
                      {" "}
                      <BsTwitter />
                    </i>
                    Twitter
                  </a>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/knowledgestreams"
                    className="d-flex align-items-center"
                  >
                    <i>
                      {" "}
                      <BsFacebook />
                    </i>
                    Facebook
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/knowstreamstech/"
                    className="d-flex align-items-center"
                  >
                    <i>
                      {" "}
                      <BsInstagram />
                    </i>
                    Instagram
                  </a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/knowledgestreams/"
                    className="d-flex align-items-center"
                  >
                    <i>
                      {" "}
                      <BsLinkedin />
                    </i>
                    Linkedin
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/@Knowledgestreams"
                    className="d-flex align-items-center"
                  >
                    <i>
                      {" "}
                      <BsYoutube />
                    </i>
                    Youtube
                  </a>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="footer_box">
                <p>Knowledge Streams © 2023 All Rights Reserved.</p>
                <p className="mt-2">Powered by dukan</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default KsFooter;
