const dev = {
    siteUrl: "https://dbservices.dukan.pk/beta/",
    base: "/beta",
    stage: "beta",
    basename: "/beta",
    assetUrl: "https://assets.dukan.pk/demo/assets/",
};

const demo = {
    siteUrl: "https://dbservices.dukan.pk/demo/",
    base: "/demo/",
    stage: "demo",
    basename: "/demo",
    assetUrl: "https://assets.dukan.pk/demo/assets/",
};

const jawad = {
    siteUrl: 'https://dbservices.dukan.pk/demo/',
    base: '/jawad/',
    stage: 'jawad',
    basename: '/jawad',
    assetUrl: 'https://assets.dukan.pk/demo/assets/'

}

const staging = {
    siteUrl: 'https://dbservices.dukan.pk/demo/',
    base: '/staging/',
    stage: 'staging',
    basename: '/staging',
    assetUrl: 'https://assets.dukan.pk/demo/assets/'

}
const beta = {
    siteUrl: "https://dbservices.dukan.pk/beta/",
    base: "/beta/",
    stage: "beta",
    basename: "/beta",
    assetUrl: "https://assets.dukan.pk/beta/assets/",
};

const live = {
    siteUrl: 'https://dbservices.dukan.pk/',
    base: '/',
    stage: 'live',
    basename: '',
    assetUrl: 'https://assets.dukan.pk/assets/'

}

const config = {
    ...(process.env.REACT_APP_STAGE === 'live' ? live : ((process.env.REACT_APP_STAGE === 'demo')? demo : (process.env.REACT_APP_STAGE === 'jawad')? jawad : (process.env.REACT_APP_STAGE === 'staging')? staging : (process.env.REACT_APP_STAGE === 'beta') ? beta : dev)),
    instagramAccount: 'https://www.instagram.com/dukanstores',
    facebookAccount: 'https://www.facebook.com/dukanpkapp',
    twitterAccount: 'https://twitter.com/Dukanstores',
    linkedinAccount: 'https://www.linkedin.com/company/dukan-pk',
    youtubeAccount: 'https://www.youtube.com/c/Dukanpk',
}
export default config