import React, { useState } from "react";
import { Nav, Accordion, useAccordionToggle, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
const KsAccordian = (props) => {
  const [show, setShow] = useState(false);
  const [show2, setshow2] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleClose2 = () => {
    setshow2(false);
  };
  const handleShow2 = () => {
    setshow2(true);
  };

  const [panelIndex, setPanelIndex] = useState();

  const CustomToggle = ({ children, eventKey }) => {
    // Decorate the click event to also set state in the component so we can
    // compare the open panel key with the state and toggle classes accordingly
    const customOnClick = useAccordionToggle(eventKey, () => {
      setPanelIndex(eventKey === panelIndex ? null : eventKey);
    });

    const customClass = eventKey === panelIndex ? "open-class" : "closed-class";

    return (
      <Nav.Link className={customClass} onClick={customOnClick}>
        {children}
      </Nav.Link>
    );
  };

  return (
    <>
      <Accordion
        defaultActiveKey="1"
        className={`accordiancategories footeraccordian ${props.bgColor}`}
      >
        <CustomToggle eventKey={1}>
          <span className="text-white font-14">Useful Links</span>
          <i className="icon-arrowdown-i"></i>
        </CustomToggle>

        <Accordion.Collapse eventKey={1}>
          <div className="footer_box">
            <div className="footer_Links">
              <Link to="/" className="d-block font-12"> Home</Link>
              <Link to="/executive-team" className="d-block font-12"> Executive Team</Link>
              <Link to="/knowledge-partner" className="d-block font-12">  Knowledge Partners</Link>
              <Link to="/university" className="d-block font-12"> University Alliances</Link>
              <Link to="/knowledge-testimonials" className="d-block font-12">Testimonials</Link>
              <Link to="/technical-programmes" className="d-block font-12">Technology</Link>
              <Link to="/soft-skill"  className="d-block font-12"> Soft Skills</Link>
              <Link to="/contactus" className="d-block font-12">Contact Us</Link>
            </div>
          </div>
        </Accordion.Collapse>
        <CustomToggle eventKey={2}>
          <span className="text-white font-14">Contact us</span>
          <i className="icon-arrowdown-i"></i>
        </CustomToggle>

        <Accordion.Collapse eventKey={2}>
          <div className="footer_box">
            <div className="footer_Links">
            <a className="d-block font-12" href="mailto:info@knowledge.tech"> Email: info@knowledge.tech</a>
              <a href="tel:+923277777272" className="d-block font-12">Call us: +923277777272</a>
              <a onClick={(e)=>e.preventDefault()} className="d-block font-12">
                Time: Mon-Fri: 10:00 AM - 5:00 PM
              </a>
            </div>
          </div>
        </Accordion.Collapse>
      </Accordion>
    </>
  );
};

export default KsAccordian;
